export function page (state, val) {
  state.page = val;
}

export function settings (state, val) {
  state.settings = val;
}

export function search (state, val) {
  state.search = val;
}

//----------------------------------------
export function leftMenu (state, val) {
  state.leftMenu = val;
}
export function nav (state, val) {
  state.nav = val;
}
export function navMain (state, val) {
  state.nav.main = val;
}
export function navTop (state, val) {
  state.nav.top = val;
}
//----------------------------------------
export function filters (state, val) {
  state.filters = val;
}
export function filtersOrigin (state, val) {
  if (!state.filters) state.filters = {
    origin: {},
    current: {}
  };

  state.filters.origin = val;
}
export function filtersCurrent (state, val) {
  if (!state.filters) state.filters = {
    origin: {},
    current: {}
  };

  state.filters.current = val;
}

//----------------------------------------
export function cart (state, val) {
  state.cart = val;
}
export function cartTotal (state, val) {
  if (!state.cart) state.cart = {
    total: null,
    products: [],
  };

  state.cart.total = val;
}
export function cartProducts (state, val) {
  if (!state.cart) state.cart = {
    total: null,
    products: [],
  };

  state.cart.products = val;
}
//----------------------------------------
