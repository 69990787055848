import {_PAGE} from "@clientConstants/constants";

const routes = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', name: _PAGE.index, component: () => import('pages/Index.vue') }
    ]
  },
  {
    path: '/order',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', name: _PAGE.order, component: () => import('pages/Order.vue') }
    ]
  },
  {
    path: '/cart',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', name: _PAGE.cart, component: () => import('pages/Cart.vue') }
    ]
  },
  {
    path: '/public/*',
    redirect: val => {
      console.info('>> REDIR', val);
      return val.params.pathMatch;
    }
  },
  {
    path: '/page/:id',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', name: _PAGE.page, component: () => import('pages/Page.vue') }
    ]
  },
  {
    path: '/catalog/:catId?',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', name: _PAGE.catalog, component: () => import('pages/Catalog.vue') }
    ]
  },
  {
    path: '/search/:search?',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', name: _PAGE.search, component: () => import('pages/Search.vue') }
    ]
  },
  {
    path: '/product/:productId',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', name: _PAGE.product, component: () => import('pages/Product.vue') }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    // component: () => import('pages/Error404.vue')
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', name: _PAGE.product, component: () => import('pages/Error404.vue') }
    ]
  }
]

export default routes
