export default {

  computed: {
    _meta() {
      return {}
    }
  },

  meta() {
    return {
      title: this._meta.title + 'Запчасти для станков, оснастка и комплектующие ООО «ОМТС»',
      // titleTemplate: x => `${ x ? x + '. ' : '' }Запчасти для станков, оснастка и комплектующие ООО «ОМТС»`,

      meta: {
        viewport: {
          name: 'viewport',
          content: 'user-scalable=no, width=460, initial-scale=0.8'
        },
    // <meta name="viewport" content="width=370, initial-scale=0.8">

        description: {
          name: 'description',
          content: this._meta.description
        },

        ogTitle: {
          property: 'og:title',
          content: this._meta.title + 'Запчасти для станков, оснастка и комплектующие ООО «ОМТС»',
          // template: x => `${ x ? x + '. ' : '' }Запчасти для станков, оснастка и комплектующие ООО «ОМТС»`,
        },
        ogDescription: {
          property: 'og:description',
          content: this._meta.description,
          // template: x => `${x}`,
        },
        ogImage: {
          property: 'og:image',
          content: this._meta.image || './img-client/omtc_logo_gear.png',
          // template: val => val || './img-client/Logo_h40.jpg',
        },
        ogType: {
          property: 'og:type',
          content: 'website'
        },
        ogUrl: {
          property: 'og:url',
          template: val => val || location.href,
        },
      },

      noscript: {
        default: 'This is content for browsers with no JS (or disabled JS)'
      }
    }
  }

}
