export function DefaultCrudRepo(Repo) {
  return {
    getAll() {
      return Repo.send('getAll');
    },
    getById(id) {
      return Repo.send('getById', {id});
    },
    getByName(name) {
      return Repo.send('getByName', {name});
    },

    create(query) {
      return Repo.send('create', query);
    },
    save(query) {
      return Repo.send('save', query);
    },
    remove(id) {
      return Repo.send('delete', {id});
    }
  };
}
