export const _CONST = {

  propType: {
    string: 1,
    number: 2,
    range: 3,
    boolean: 4,
    array: 5,
  },

  filterType: {
    range: 1,
    boolean: 2,
    select: 3,
    check: 4,
  },

  navType: {
    page: 'page',
    url: 'url',
    nav: 'nav',
  },

  propRelation: {
    'gt': '>',
    'gte': '>=',
    'lt': '<',
    'lte': '<=',
    'e': '=',
  },

  viewType: {
    list: 'list',
    tiles: 'tiles',
    table: 'table',
  },

  orderResult: {
    success: 'success',
    error: 'error',
  }

}

export const _ICON = {
  refresh: 'refresh',
  add: 'add',
  // addToCart: 'add_shopping_cart',
  addToCart: 'mdi-cart-outline',
  grain: 'grain',
  school: 'school',
  pages: 'pages',
  delete: 'delete',
  order: 'assignment_turned_in',
  clear: 'clear',
  edit: 'edit',
  back: 'keyboard_arrow_left',
  save: 'save',
  navs: 'menu_book',
  settings: 'settings',
  search: 'search',
  products: 'shopping_cart',
  shoppingCart: 'shopping_cart',
  addCat: 'create_new_folder',
  cat: 'folder',
  done: 'done',
  addProduct: 'add_shopping_cart',
  product: 'shopping_basket',
  shipping: 'local_shipping',
  list: 'view_list',
  tiles: 'view_module',
  table: 'table_chart',
  emailSign: 'mail_outline',
  phone: 'phone_iphone',
  nav: 'near_me',
}

export const _PAGE = {
  index: 'index',
  product: 'product',
  catalog: 'catalog',
  pages: 'pages',
  page: 'page',
  cart: 'cart',
  order: 'order',
  search: 'search',
}

export const _COLOR = {
  positive: 'positive',
  negative: 'negative',
  warning: 'warning',
  info: 'info',
  primary: 'primary',
  accent: 'accent',
  secondary: 'secondary',
  grey: 'grey',
  brown: 'brown',
  dark: 'dark',
}
