export default function () {
  return {
    page: {},

    nav: {
      main: [],
      top: [],
    },

    settings: {},

    search: null,

    cart: {
      total: null,
      products: [],
    },

    filters: {
      origin: {},
      current: {}
    },

    leftMenu: [],
  }
}
