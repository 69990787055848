import Vue from 'vue'

Vue.filter('filterType', value => {
  const items = {
    1: 'Диапазон',
    2: 'Логический',
    3: 'Выбор',
    4: 'Множестенный выбор',
  };

  return items[value] || value;
});
