import Vue from 'vue'
import Vuex from 'vuex'

import main from './module-main'

Vue.use(Vuex)

const Store = new Vuex.Store({
  modules: {
    main
  },

  strict: process.env.DEV
});

if (process.env.DEV && module.hot) {
  module.hot.accept(['./module-main'], () => {
    const neMain = require('./module-main').default;
    Store.hotUpdate({ modules: { main: neMain } });
  })
}

export default Store;
