export default {

  methods: {
    _lsSet(key, value) {
      localStorage.setItem(this.$config.LS_KEY + key, JSON.stringify(value));
    },
    _lsGet(key) {
      const value = localStorage.getItem(this.$config.LS_KEY + key);

      try {
        return JSON.parse(value);
      } catch (e) {
        return null;
      }
    }
  }

}
