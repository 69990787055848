import Vue from "vue";

console.info('> INIT BASE REPO', Vue.prototype.$config)

const axios = Vue.prototype.$axios;

const appUrl = new URL(Vue.prototype.$config.SERVER_URL + '/api/' + Vue.prototype.$config.API_PREFIX);

// const getAppUrl = () => {
//   if (!Vue.prototype.$config) return {};
//   return new URL(Vue.prototype.$config.SERVER_URL + '/api/' + Vue.prototype.$config.API_PREFIX);
// };

export class RepoBase {

  // controllerName = null;

  constructor(controllerName) {
    // this.controllerName = controllerName;
    this.pathUrl = new URL(appUrl.pathname + '/' + controllerName, appUrl);
  }

  // getPathUrl() {
  //   return this.pathUrl = new URL(getAppUrl().pathname + '/' + this.controllerName, getAppUrl());
  // }

  pathUrl = appUrl.href;

  send(method, query = {}) {
    query = {
      ...query,
      __no_cache: Vue.prototype.$config.IS_AUTH,
      method,
    };
    return new Promise((resolve, reject) => {
      axios.post(this.pathUrl.href, query)
        .then(res => {
          if (res.data.state === 0) {
            resolve(res);
            return;
          }

          reject(res);
        })
        .catch(reject)
    });

  }
}
