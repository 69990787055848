import Vue from 'vue'
import Vuelidate from 'vuelidate'
import { Screen } from 'quasar'
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);

import '@clientFilters/filters'

Vue.use(Vuelidate);

//----------------------------------------

//----------------------------------------
const getConfig = async () => {
  const getMeta = metaName => {
    const eMeta = document.querySelector(`meta[name="${metaName}"]`);
    if (!eMeta) return null;

    return eMeta.getAttribute('content');
  };

  const config = {
    CSRF: getMeta('csrf-token'),
    IS_DEV: getMeta('is-dev') === 'true',
    IS_AUTH: getMeta('is-auth') === '1' || getMeta('is-dev') === 'true',
    APP_URL: getMeta('app-url'),
    SERVER_URL: getMeta('server-url'),
    API_PREFIX: getMeta('api-prefix'),
    DIR: getMeta('dir'),
    LS_KEY: 'OMTC_SHOP_'
  };

  Vue.prototype.$config = {
    ...Vue.prototype.$config,
    ...config
  }


  console.info('>> CONFIG', Vue.prototype.$config)
}
//----------------------------------------

Screen.setSizes({ sm: 400, md: 800, lg: 1200, xl: 2000 })



const init = async () => {
  await getConfig();
};

init();











// "async" is optional;
// more info on params: https://quasar.dev/quasar-cli/cli-documentation/boot-files#Anatomy-of-a-boot-file
export default async (/* { app, router, Vue ... } */) => {
  // something to do
}


