<template>
  <div id="q-app">
    <router-view />
  </div>
</template>
<script>

  // mixins
  // import settingsMixin from "@clientMixins/settingsMixin";
  import cartMixin from "@clientMixins/cartMixin";
  import metaMixin from "src/mixins/metaMixin";

  export default {
    name: 'App',

    mixins: [
      cartMixin,
      metaMixin
    ],

    computed: {
      _meta() {

        return {
          title: '',
          description: '',
        }
      }
    },

    watch: {
      '$route.name'(val) {
        console.info('>> W -> $route.name', val)
        const cName = 'page-full-width';
        document.body.classList.remove(cName);
        console.info('>> clear body', document.body.classList)
      }
    },

    methods: {
      init() {
        console.info('>> INIT SETTINGS', this.$config)
        // this._settingsRefresh();
        this._cartRestoreFromLs();
      },
    },

    mounted() {
      this.init();
    },

    // meta() {
    //   return {
    //
    //     title: '',
    //     titleTemplate: title => `${title} - ООО «ОМТС» Запасные части к станкам, оснастка и комплектующие`,
    //
    //     meta: {
    //       description: { name: 'description', content: 'ООО «ОМТС» Запасные части к станкам, оснастка и комплектующие' },
    //
    //       ogTitle:  {
    //         name: 'og:title',
    //         template: val => `${val} - ООО «ОМТС» Запасные части к станкам, оснастка и комплектующие`,
    //       },
    //
    //       ogDescription:  {
    //         name: 'og:description',
    //         template: val => `${val} - ООО «ОМТС» Запасные части к станкам, оснастка и комплектующие`,
    //       },
    //
    //       ogType: {
    //         name: 'og:type',
    //         content: 'website'
    //       },
    //
    //       ogUrl: {
    //         name: 'og:url',
    //         template: val => val || location.href,
    //       },
    //
    //       ogImage: {
    //         name: 'og:image',
    //         template: val => val || './img-client/Logo_h40.jpg',
    //       }
    //     },
    //
    //     noscript: {
    //       default: 'This is content for browsers with no JS (or disabled JS)'
    //     }
    //   }
    // },
  }
</script>
