import lsMixin from '@clientMixins/lsMixin'

export default {
  mixins: [
    lsMixin,
  ],

  computed: {
    _cart: {
      get() {
        return this.$store.getters['main/cart'];
      },
      set(val) {
        this.$store.commit('main/cart', val);
      }
    },
  },

  methods: {
    _cartClear() {
      this._cart = {
        total: null,
        products: [],
      };
    },
    _cartRestoreFromLs() {
      const lsCart = this._lsGet('cart');
      if (lsCart) this._cart = lsCart;
    },
    _cartSetProducts(val) {
      this.$store.commit('main/cartProducts', val);
    },
    _cartRemoveProduct(product) {
      let products = [];
      this._cart.products.forEach(x => {
        if (x.product.id !== product.id) products.push(x);
      })

      console.info('>> _cartRemoveProduct', product, products)
      this.$store.commit('main/cartProducts', products);
    },
    _cartAddProduct(val, count) {
      console.info('>> _cartAddProduct', val, this._cart.products)
      if (this._cart.products.find(x => +x.id === +val.id)) return false;

      this.$store.commit('main/cartProducts', [
        ...this._cart.products,
        {
          product: val,
          count
        }
      ]);

      return true;
    },
    _cartHasProduct(val) {
      if (!val) return false;
      return !!this._cart.products.find(x => +x.product.id === +val.id);
    }
  },

  watch: {
    '_cart.products': {
      deep: true,
      handler(val) {
        let total = 0;

        val.forEach(x => {
          if (x.product.price > 0) total += x.product.price * x.count;
        });

        this.$store.commit('main/cartTotal', total);

        this.$nextTick(() => {
          this._lsSet('cart', this._cart);
        });
      }
    }
  },

  mounted() {

  }
}
