import Vue from 'vue'
import store from '@clientStore/index'
import {SettingsRepo} from "@clientServices/settingsRepo";

//----------------------------------------
const getSettings = async () => {
  const res = await SettingsRepo.getByName('main');
  console.info('>> Get Settings', res)
  store.commit('main/settings', res.data.item.value);
}
//----------------------------------------




const init = async () => {
  await getSettings();
};

init();











// "async" is optional;
// more info on params: https://quasar.dev/quasar-cli/cli-documentation/boot-files#Anatomy-of-a-boot-file
export default async (/* { app, router, Vue ... } */) => {
  // something to do
}


