export function page (state) {
  return state.page;
}

export function settings (state) {
  return state.settings;
}

export function search (state) {
  return state.search;
}

export function cart (state) {
  return state.cart;
}

export function filters (state) {
  return state.filters;
}

export function leftMenu (state) {
  return state.leftMenu;
}

export function nav (state) {
  return state.nav;
}

export function navMain (state) {
  return state.nav?.main;
}

export function navTop (state) {
  return state.nav?.top;
}
